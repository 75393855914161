import '../../ads/safeframe-host';
import { trackAdDiv } from '../../ads/ads-in-view';

const setCookie = (name, value = '', days) => {
	let expires = '';

	if (days) {
		const date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = "; expires=" + date.toUTCString();
	}

	document.cookie = `${name}=${value}${expires}; path=/`;
}

// Check for 3rd party cookies support
const setUserIDThirdParty = (cookie) => setCookie('userid3p', cookie, 365);
window.addEventListener('message', (evt) => {
	// Origins should be verified during cross-origin communications
	if (evt.origin !== 'https://www.awxcdn.com') {
		return;
	}

	if (evt.data === '3PCsupported') {
		const { uspString } = window.globalAdConfig;
		const isOptedOut = uspString && uspString[2] === 'Y';
		setUserIDThirdParty(isOptedOut ? 'restricted' : 'active');
	} else if (evt.data === '3PCunsupported') {
		setUserIDThirdParty('missing');
	}
});
const thirdPartyIframe = document.createElement('iframe');
thirdPartyIframe.setAttribute('src', 'https://www.awxcdn.com/adc/3rdpartycheck.html');
thirdPartyIframe.setAttribute('style', 'width:0;height:0;display:none');
document.body.appendChild(thirdPartyIframe);

// Measure time on page https://accuweather.atlassian.net/browse/RW-714
if (performance?.now) {
	const bucketSize = 2000;
	const interval = 500;
	let currentBucket = -1;
	let hasRun = false;

	setInterval(() => {
		const bucket = Math.floor(performance.now() / bucketSize) * 2;
		if (bucket > currentBucket || !hasRun) {
			currentBucket = bucket;
			localStorage.setItem('timeOnPage', currentBucket);
			hasRun = true;
		}
	}, interval);
}

// Post-bid logic
window.googletag = window.googletag || { cmd: [] };
googletag.cmd.push(() => {
	googletag.pubads().addEventListener('slotRenderEnded', (event) => {
		// Start tracking ad view-ability when slot is rendered
		// Ids that end with -oop are tracking slots for Buy It Now slots
		let adDivId = event.slot.getSlotElementId();
		if (adDivId && typeof adDivId === 'string') {
			adDivId = adDivId.replace('-oop', '');
		}
		trackAdDiv(document.getElementById(adDivId));

		const adObject = window.adsOnPage.get(adDivId);

		if (adObject) {
			// TODO: Mark winner.auction 2 if google ad is returned
			// if (adObject.gptSlot?.getResponseInformation() && !adObject.winner.auction) {
			// 	adObject.winner.auction = 2;
			// }

			// If ad returned by DFP is empty, render the highest available bid returned.
			// If no bid is available yet, wait for timeout and check again
			// If there's still no winning bid, inject house ad
			if (event.isEmpty) {
				const maybeRenderPrebid = () => {
					let highestBid = getHighestCpmNonNativeBid(adObject.adUnitCode);
					if (highestBid) {
						// TODO: Mark winner.auction 3 if posbid wins
						// if (!adObject.winner.auction) {
						// 	adObject.winner.auction = 3;
						// }
						renderPrebidAndCreateTrackingPixel(highestBid, adObject);
						return true;
					}

					return false;
				};

				window.prebidBidPromise.then(() => {
					if (!maybeRenderPrebid()) {
						window.prebidTimeoutPromise.then(() => {
							if (!maybeRenderPrebid()) {
								// TODO: Mark winner.auction 0 if postbid is not available
								// adObject.winner.auction = 0;
								injectHouseAd(adObject);
							}
						});
					}
				});
			}
		}
	});
});

const injectHouseAd = (adObject) => {
	if (!adObject) return;

	const { adUnitCode, slot, adDivId } = adObject;
	const isMobile = adUnitCode.split('/')[2] !== 'web';
	const validSlots = isMobile ?
		['top', 'middle', 'adhesion', 'bottom', 'native'] :
		['top', 'top_right', 'bottom', 'bottom_right'];

	if (validSlots.includes(slot)) {
		let size = '300x250';
		if (!isMobile && (slot === 'top' || slot === 'bottom')) {
			size = '970x250';
		} else if (slot === 'adhesion') {
			size = '320x50';
		}

		const adSlotEl = document.getElementById(adDivId);
		if (adSlotEl) {
			const url = createPostbidUrl(adObject, null, size);
			const scriptEl = document.createElement('script');
			scriptEl.setAttribute('src', url + `&m=text/javascript`);
			adSlotEl.appendChild(scriptEl);
		}
	}
};

const getHighestCpmNonNativeBid = (adCode) => {
	if (pbjs?.getBidResponsesForAdUnitCode) {
		const bids = pbjs.getBidResponsesForAdUnitCode(adCode).bids;
		const bid = bids
			// Filter bids with valid ad (native ads have bid.native instead of bid.ad)
			.filter((b) => b.ad)
			// Filter bids that are not rendered yet
			.filter((b) => b.status && b.status !== 'rendered')
			.reduce((prev, curr) => (prev.cpm > curr.cpm ? prev : curr), {
				bidderCode: '',
				cpm: 0.0,
			});

		if (bid.cpm >= 0.02) {
			return bid;
		}
	}

	return null;
};

const renderPrebidAndCreateTrackingPixel = (bid, adObject) => {
	if (!bid) return;

	if (adObject?.gptSlot) {
		// Prebid looks for this key value pair to target the div where the safeframe is located
		adObject.gptSlot.setTargeting('hb_adid', bid.adId);
	}

	// Render highest bid in ad slot
	window.renderPrebidWithIframe(adObject.adDivId, bid);

	// Inject postbid tracking pixel
	const url = createPostbidUrl(adObject, bid.adserverTargeting);
	const iframe = document.createElement('iframe');
	iframe.setAttribute('src', url);
	iframe.setAttribute('style', 'width:0;height:0;display:none');
	document.body.appendChild(iframe);
};

const createPostbidUrl = (adObject, targeting = {}, size = '1x1') => {
	const objectToQueryString = (obj) => Object.keys(obj)
		.map((key) => `${key}=${obj[key]}`)
		.join('&');

	const adCode = adObject.adUnitCode.split('/');
	adCode[3] = 'postbid';

	// Combine targeting with adinfo and ppid
	const _targeting = {
		...targeting,
		...(window.adInfo || {}),
		...window.getUprTargeting(adObject),
		ppid: window.globalAdConfig.ppid,
	}

	const queryObj = {
		iu: adCode.join('/'),
		sz: size,
		c: Math.round(new Date().getTime() / 1000),
		t: encodeURIComponent(objectToQueryString(_targeting)),
	};

	return `https://securepubads.g.doubleclick.net/gampad/adx?${objectToQueryString(queryObj)}`;
};

// Async function to display a reward video ad
// Returns boolean for whether reward was granted to user
window.displayRewardAd = () => {
	return new Promise((resolve) => {
		googletag.cmd.push(() => {
			const currentPage = window.location.hash;
			let rewardGranted = false;

			// Create rewarded ad unit code
			const adData = window.tagEngineDataLayer.adData[currentPage.replace('#', '') || 'home'];
			let adUnitCode = Object.values(adData.adSlots)[0].adUnitCode.split('/');
			adUnitCode[4] = 'rewarded';

			let rewardedSlot = googletag.defineOutOfPageSlot(
				adUnitCode.join('/'),
				googletag.enums.OutOfPageFormat.REWARDED,
			);

			if (rewardedSlot) {
				rewardedSlot.addService(googletag.pubads());

				const onrewardedSlotReady = (event) => event.makeRewardedVisible();
				const onrewardedSlotGranted = () => rewardGranted = true;
				const onRewardedSlotClosed = () => {
					// Reward ad changes the url fragment so we have to change it back
					window.location.hash = currentPage;

					// Cleanup
					googletag.destroySlots([rewardedSlot]);
					googletag.pubads().removeEventListener('rewardedSlotReady', onrewardedSlotReady);
					googletag.pubads().removeEventListener('rewardedSlotGranted', onrewardedSlotGranted);
					googletag.pubads().removeEventListener('rewardedSlotClosed', onRewardedSlotClosed);

					// Resolve promise
					resolve(rewardGranted);
				}

				googletag.pubads().addEventListener('rewardedSlotReady', onrewardedSlotReady);
				googletag.pubads().addEventListener('rewardedSlotGranted', onrewardedSlotGranted);
				googletag.pubads().addEventListener('rewardedSlotClosed', onRewardedSlotClosed);

				googletag.enableServices();
				googletag.display(rewardedSlot);
				googletag.pubads().refresh([rewardedSlot]);
			}
		});
	});
};
